import * as React from 'react';
import { graphql } from 'gatsby';
import PostCard from '../components/post-card/post-card';
import Pagination from '../components/pagination/pagination';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { BlogPostsWrapper } from './templates.style';

const BlogList = (props: any) => {
  const { data } = props;
  const Posts = data.allContentfulBlogPost.edges;
  const { defaultTitle } = data.site.siteMetadata;
  const { currentPage, numPages } = props.pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1 ? '/page/1' : `/page/${(currentPage - 1).toString()}`;
  const nextPage = `/page/${(currentPage + 1).toString()}`;
  const PrevLink = !isFirst && prevPage;
  const NextLink = !isLast && nextPage;

  return (
    <Layout>
      <SEO title={`${defaultTitle} หน้า ${currentPage}`} />

      <BlogPostsWrapper>
        {Posts.map(({ node }: any) => {
          return (
            <PostCard
              key={node.slug}
              title={node.title || node.slug}
              image={node.featured == null ? null : node.featured.fluid}
              url={`/${node.slug}`}
              description={node.excerpt.excerpt}
              date={node.createdAt}
              tags={node.tags}
            />
          );
        })}

        <Pagination
          prevLink={PrevLink}
          nextLink={NextLink}
          currentPage={`${currentPage}`}
          totalPage={`${numPages}`}
        />
      </BlogPostsWrapper>
    </Layout>
  );
};

export default BlogList;

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    allContentfulBlogPost(
      sort: { fields: createdAt, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt {
            excerpt
          }
          slug
          createdAt(formatString: "DD [<span>] MMMM [</span>]")
          title
          tags {
            slug
            name
          }
          featured {
            fluid(maxWidth: 1170, quality: 90) {
              ...GatsbyContentfulFluid_withWebp
            }
            fixed(width: 1170, height: 690) {
              ...GatsbyContentfulFixed_withWebp
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        defaultTitle
      }
    }
  }
`;
